<template>
    <a-modal
		class="pwa-modal"
		:visible="active"
		:centered="true"
		:closable="false"
		:title="$t('rapportedit.sending.options.title')"
		:okText="$t('rapportedit.sending.options.confirm')"
		:cancelText="$t('common.button.cancel')"
		@cancel="cancelOptionsModal"
		@ok="submitReport"
    >
		<a-row>
			<a-col :span="20">{{ this.$t('rapportedit.sending.options.keep_mail_content') }}</a-col>
			<a-col :span="4" style="text-align:right">
				<a-switch :checked="keep_mail_value" @change='changeKeepMailValue' />
			</a-col>
		</a-row>		
    </a-modal>
</template>

<script>
import User_db from "~/db_access/User";
export default {
    name: "SendOptionsModal",
    props:{
        active:{
            type:Boolean,
            required:true,
        },
		modele_id: {
			type: Number,
			required: true,
		},
		content: {
			type: String,
			required: true,
		}
    },
	data() {
		return {
			keep_mail_value: true,
		}
	},
	methods: {
		cancelOptionsModal() {
			this.$emit('cancelModal');
			this.keep_mail_value = true;
		},
		async submitReport() {
			if (this.keep_mail_value === true) {
				await User_db.set_user_mail_content(this.modele_id, this.content);
			}
			this.$emit('send');
			this.keep_mail_value = true;
		},
		changeKeepMailValue(value) {
			this.keep_mail_value = value;
		}
	}
}
</script>
